import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { breakpoints } from '~styles/global'
import Image from '~components/Image'
import RichText from '~components/RichText'
import Button from '~components/blocks/Button'
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'

const BrandCardExpandable = ({ className, image, title, country, text, link, id }) => {

  const {mobile} = breakpoints
  const [open, setOpen] = useState(false)

  return (
    <div
      id={id}
      className={className}
      css={css`
        display: grid;
        grid-template-rows: max-content;
        text-align: left;
        ${mobile}{
          opacity: 1;
        }
      `}
    >
      <div css={css`
        margin-bottom: 28px;
        ${mobile}{ 
          margin-bottom: 26px;
        }
      `}> 
        <Image
          asset={image}
          aspectRatio={1.5116}
        />
      </div>
      <div css={css`
        display: grid;
        ${mobile}{ 
          grid-template-columns: 1fr;
          align-items: end;
        }
      `}>
        <div css={css`
          margin-bottom: 30px;
          ${mobile}{ 
            margin-bottom: 20px;
          }
        `}>
          <span 
            className="h4"
            css={css`
              margin-right: 9px;
            `}>
              {title}
          </span>
          <span>{country}</span>
        </div>
        <SlideDown 
          className={'my-dropdown-slidedown'} 
          css={css`
            transition: 0.4s ease-in-out;
        `}>
          <div css={css`
            margin-bottom: 30px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: ${open ? `100`: `2`} ; /* number of lines to show */
            -webkit-box-orient: vertical;
          `}>
            <RichText content={text}/>
          </div>
        </SlideDown>
        <Button 
          noUnderline 
          onClick={() => setOpen(!open)}
          css={css`
            align-items: end;
          `}
        >More Info +</Button>
      </div>
    </div>
  )
}

BrandCardExpandable.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  text: PropTypes.array,
  country: PropTypes.string,
  link: PropTypes.string,
  className: PropTypes.string,
}

BrandCardExpandable.defaultProps = {
  title: 'Capdell',
  country: 'Spain',
  text: [
      {
          "_key": "bb4b9cd400f4",
          "_type": "block",
          "children": [
              {
                  "_key": "f0e28aa4675d",
                  "_type": "span",
                  "marks": [],
                  "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
              }
          ],
          "markDefs": [],
          "style": "normal"
      }
  ],
  country: 'Spain',
  link: `/`,
}

export default BrandCardExpandable
