import React from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { breakpoints } from '~styles/global'
import Section from '~components/Section'
import * as Flickity from 'react-flickity-component'
import "~styles/flickity.css";
import { Cross, CrossThick, Larr, Rarr } from '../../Svg';
import BrandSlide from '../../blocks/BrandSlide';


class BrandSliderBlock extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      currentSlide: this.props.slideToShow,
    }
  }

  componentDidMount = () => {
    // You can register events in componentDidMount hook
    this.flkty.on('change', () => {
      this.change()
      this.props.onSlide(this.flkty.selectedIndex)
    })
  }

  componentDidUpdate = prevProps => {
    if (
      this.props.slideToShow !== prevProps.slideToShow
      // && this.props.slideChange > -1
      ) {
        this.flkty.select( this.props.slideToShow, false, true )
    }
  }

  change() {
    this.setState({
      currentSlide: this.flkty.selectedIndex
    })
  }

  render() {

    const {mobile} = breakpoints

    const myCustomPrev = () => {
      this.flkty.previous()
    }

    const myCustomNext = () => {
      this.flkty.next()
    }

    const flickityOptions = {
      prevNextButtons: false,
      pageDots: false,
      adaptiveHeight: true,
      wrapAround: true,
      initialIndex: this.props.slideToShow,
      lazyload: 5,
    }
    
    const arrowCss= `
      height: 16px;
      width: 16px;
      transform: scale(1);
      transition: transform 0.3s;
      &:hover{
        transform: scale(1.15);
      }
      ${mobile}{
        height: 12px;
        width: 12px;
        &:hover{
          transform: none;
        }
      }
    `

    return (
      <Section
        className={this.props.className}
      >
        <div css={css`
          grid-column: 4/10;
          display: grid;
          ${mobile}{
            grid-column: span 12;
          }
        `}>
          <button 
            onClick={() => this.props.onClose()}
            css={css`
              justify-self: end;
              margin-bottom: 27px;
              grid-column: 5/7;
              ${mobile}{
                grid-column: span 12;
                margin-bottom: 18px;
              }
          `}>
            <Cross 
              css={css`
                width: 16px;
                height: 16px;
                ${mobile}{
                  display: none;
                }
            `}/>
            <CrossThick 
              css={css`
                width: 12px;
                height: 12px;
                display: none;
                ${mobile}{
                  display: block;
                }
            `}/>
          </button>
        </div>
        <Flickity
          css={css`
            grid-column: 4/10;
            margin-bottom: 62px;
            ${mobile}{
              grid-column: span 12;
              margin-bottom: 48px;
            }
          `}
          flickityRef={c => this.flkty = c}
          className={'carousel'} // default ''
          elementType={'div'} // default 'div'
          options={flickityOptions} // takes flickity options {}
          disableImagesLoaded={false} // default false
          // static // default false
        >
        {this.props.brands?.map(brand => (
          <BrandSlide brand={brand} key={brand.node?.id} css={css`margin-right: 20px;`}/>
        ))}
        </Flickity>
        <div css={css`
          grid-column: 4/10;
          display: grid;
          grid-template-columns: repeat(6, 1fr);
          grid-column-gap: 20px;
          ${mobile}{
            grid-column-gap: 10px;
            grid-column: span 12;
          }
        `}>
          <button
            onClick={() => myCustomPrev()}
            className="h6"
            css={css`
              grid-column: 1/2;
              justify-self: start;
              display: flex;
              align-items: center;
          `}>
            <Larr css={css`
              margin-right: 8px;
              height: 10px;
              width: 10px;
            `}/>
            Previous
          </button>
          <button
            onClick={() => myCustomNext()}
            className="h6"
            css={css`
              grid-column: 5/7;
              justify-self: end;
              display: flex;
              align-items: center;
          `}>
            Next
            <Rarr css={css`
              margin-left: 8px;
              height: 10px;
              width: 10px;
            `}/>
          </button>
        </div>
      </Section>
    )
  }
}





const BrandDetailSection = (props) => {
  return <BrandSliderBlock {...props} />
}

BrandDetailSection.propTypes = {
  className: PropTypes.string,
  brands: PropTypes.arrayOf(PropTypes.shape({
    node: PropTypes.shape({
      key: PropTypes.string,
      id: PropTypes.string,
      slug: PropTypes.shape({
        current: PropTypes.string
      }),
      _rawContent: PropTypes.shape({
        title: PropTypes.string,
        country: PropTypes.string,
        image: PropTypes.shape({
          asset: PropTypes.shape({
            id: PropTypes.string
          })
        }),
        text: PropTypes.array,
      }),
    }),
  })),
  slideToShow: PropTypes.number,
  onSlide: PropTypes.func,
  onClose: PropTypes.func,
}

BrandDetailSection.defaultProps = {
  slideToShow: 3,
  onSlide: n => {},
  onClose: () => {},
  brands: [
    {
      node: {
        key: `1`,
        id: `1`,
        slug: {
          current: `/`
        },
        title: `cattelan Italia 1`,
        _rawContent: {
          country: `Italy`,
          image: {
            asset: {
              id: null
            }
          },
          text: [
            {
                "_key": "f9d17a10baa5",
                "_type": "block",
                "children": [
                    {
                        "_key": "8f25454437bd",
                        "_type": "span",
                        "marks": [],
                        "text": "CATTELAN ITALIA infuse luxe living with state of the art design and quality materials, for innovative products with a strong visual impact and design longevity within the home."
                    }
                ],
                "markDefs": [],
                "style": "normal"
            },
            {
                "_key": "8b2a5b979d61",
                "_type": "block",
                "children": [
                    {
                        "_key": "b8c2c46be150",
                        "_type": "span",
                        "marks": [],
                        "text": "Established in 1979 by Giorgio Cattelan, who came from a long family heritage in furniture design, CATTELAN ITALIA initially launched with pieces that incorporated marble elements. This quickly expanded to a contemporary mix of elements including wood, glass, leather and metal to create the unique pieces that have marked CATTELAN ITALIA as a leading brand in the furniture design industry."
                    }
                ],
                "markDefs": [],
                "style": "normal"
            }
          ]
        }
      }
    },
    {
      node: {
        key: `2`,
        id: `2`,
        slug: {
          current: `/`
        },
        title: `cattelan Italia 2`,
        _rawContent: {
          country: `Italy`,
          image: {
            asset: {
              id: null
            }
          },
          text: [
            {
                "_key": "f9d17a10baa5",
                "_type": "block",
                "children": [
                    {
                        "_key": "8f25454437bd",
                        "_type": "span",
                        "marks": [],
                        "text": "CATTELAN ITALIA infuse luxe living with state of the art design and quality materials, for innovative products with a strong visual impact and design longevity within the home."
                    }
                ],
                "markDefs": [],
                "style": "normal"
            },
            {
                "_key": "8b2a5b979d61",
                "_type": "block",
                "children": [
                    {
                        "_key": "b8c2c46be150",
                        "_type": "span",
                        "marks": [],
                        "text": "Established in 1979 by Giorgio Cattelan, who came from a long family heritage in furniture design, CATTELAN ITALIA initially launched with pieces that incorporated marble elements. This quickly expanded to a contemporary mix of elements including wood, glass, leather and metal to create the unique pieces that have marked CATTELAN ITALIA as a leading brand in the furniture design industry."
                    }
                ],
                "markDefs": [],
                "style": "normal"
            }
          ]
        }
      }
    },
    
  ]
}

export default BrandDetailSection
